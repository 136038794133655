#page{
  height: 100%;
  display: flex; -js-display: flex; flex:1;
  justify-content: center;
}
.quiz-wrap{
  width: 100%;
  max-width: 304px;
  display: flex; -js-display: flex; flex:1;
  align-content: center;
  flex-direction: column
}
.logo-heading{
  max-width: 280px;
  margin-top: 40px;
  display: block;
  margin: 40px auto 20px;
}
.main-btn{
  border: solid 2px;
  padding: 6px 10px;
  border-radius: 40px;
  background: transparent;
  font-size: 18px;
  width: 280px;
  transition: all .2s ease-in-out;
}
@media screen and (min-width: 768px){  
  .logo-heading{
    max-width: 300px; 
  }
  .quiz-wrap{
    max-width: 744px;
  }
}
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
.spinning{
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin-in {
  0% { transform:rotate(0deg); }
  30% { transform:rotate(360deg); }
  31% { transform:rotate(00deg); }
  68%, 72% { left: 50px; }
  100% { top: 100px; left: 100%; }
}
.spin-in{
  animation-name: spin-in;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes wobble {
  from {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  1% {
    -webkit-transform: scale(0.086484529651881, 0.179427456557767);
            transform: scale(0.086484529651881, 0.179427456557767);
  }
  2% {
    -webkit-transform: scale(0.292554252432152, 0.545376716848168);
            transform: scale(0.292554252432152, 0.545376716848168);
  }
  3% {
    -webkit-transform: scale(0.545376716848168, 0.894404964543183);
            transform: scale(0.545376716848168, 0.894404964543183);
  }
  4% {
    -webkit-transform: scale(0.788551391390369, 1.120376016086594);
            transform: scale(0.788551391390369, 1.120376016086594);
  }
  5% {
    -webkit-transform: scale(0.985835941661639, 1.205153326404069);
            transform: scale(0.985835941661639, 1.205153326404069);
  }
  6% {
    -webkit-transform: scale(1.120376016086594, 1.184807461549862);
            transform: scale(1.120376016086594, 1.184807461549862);
  }
  7% {
    -webkit-transform: scale(1.191045818185745, 1.113400777234015);
            transform: scale(1.191045818185745, 1.113400777234015);
  }
  8% {
    -webkit-transform: scale(1.207445230574602, 1.037247338712057);
            transform: scale(1.207445230574602, 1.037247338712057);
  }
  9% {
    -webkit-transform: scale(1.184807461549862, 0.983312126349848);
            transform: scale(1.184807461549862, 0.983312126349848);
  }
  10% {
    -webkit-transform: scale(1.139672272493081, 0.959151493096828);
            transform: scale(1.139672272493081, 0.959151493096828);
  }
  11% {
    -webkit-transform: scale(1.086773838727188, 0.959207005548428);
            transform: scale(1.086773838727188, 0.959207005548428);
  }
  12% {
    -webkit-transform: scale(1.037247338712057, 0.972534530793938);
            transform: scale(1.037247338712057, 0.972534530793938);
  }
  13% {
    -webkit-transform: scale(0.998010256157525, 0.98880159678671);
            transform: scale(0.998010256157525, 0.98880159678671);
  }
  14% {
    -webkit-transform: scale(0.972031419546276, 1.001379435012348);
            transform: scale(0.972031419546276, 1.001379435012348);
  }
  15% {
    -webkit-transform: scale(0.959151493096828, 1.00783265522376);
            transform: scale(0.959151493096828, 1.00783265522376);
  }
  16% {
    -webkit-transform: scale(0.957140100254271, 1.008821093113781);
            transform: scale(0.957140100254271, 1.008821093113781);
  }
  17% {
    -webkit-transform: scale(0.96274018852263, 1.006488198218788);
            transform: scale(0.96274018852263, 1.006488198218788);
  }
  18% {
    -webkit-transform: scale(0.972534530793938, 1.003092956925791);
            transform: scale(0.972534530793938, 1.003092956925791);
  }
  19% {
    -webkit-transform: scale(0.983552526255917, 1.000221414749495);
            transform: scale(0.983552526255917, 1.000221414749495);
  }
  20% {
    -webkit-transform: scale(0.993604544681563, 0.998573305048547);
            transform: scale(0.993604544681563, 0.998573305048547);
  }
  21% {
    -webkit-transform: scale(1.001379435012348, 0.998133854506318);
            transform: scale(1.001379435012348, 0.998133854506318);
  }
  22% {
    -webkit-transform: scale(1.006367469874671, 0.998500975623806);
            transform: scale(1.006367469874671, 0.998500975623806);
  }
  23% {
    -webkit-transform: scale(1.00867876840649, 0.999191084459488);
            transform: scale(1.00867876840649, 0.999191084459488);
  }
  24% {
    -webkit-transform: scale(1.008821093113781, 0.999833501948367);
            transform: scale(1.008821093113781, 0.999833501948367);
  }
  25% {
    -webkit-transform: scale(1.007486437375814, 1.000240340703161);
            transform: scale(1.007486437375814, 1.000240340703161);
  }
  26% {
    -webkit-transform: scale(1.005378103626964, 1.000385276149533);
            transform: scale(1.005378103626964, 1.000385276149533);
  }
  27% {
    -webkit-transform: scale(1.003092956925791, 1.000339232175458);
            transform: scale(1.003092956925791, 1.000339232175458);
  }
  28% {
    -webkit-transform: scale(1.001059752569101, 1.000203385122328);
            transform: scale(1.001059752569101, 1.000203385122328);
  }
  29% {
    -webkit-transform: scale(0.999525049489696, 1.000062534362191);
            transform: scale(0.999525049489696, 1.000062534362191);
  }
  30% {
    -webkit-transform: scale(0.998573305048547, 0.999964849826148);
            transform: scale(0.998573305048547, 0.999964849826148);
  }
  31% {
    -webkit-transform: scale(0.998166624576185, 0.999922697095762);
            transform: scale(0.998166624576185, 0.999922697095762);
  }
  32% {
    -webkit-transform: scale(0.998191241770966, 0.999924761163581);
            transform: scale(0.998191241770966, 0.999924761163581);
  }
  33% {
    -webkit-transform: scale(0.998500975623806, 0.999950419122189);
            transform: scale(0.998500975623806, 0.999950419122189);
  }
  34% {
    -webkit-transform: scale(0.998951615476555, 0.999980662109647);
            transform: scale(0.998951615476555, 0.999980662109647);
  }
  35% {
    -webkit-transform: scale(0.99942365059167, 1.000003563281379);
            transform: scale(0.99942365059167, 1.000003563281379);
  }
  36% {
    -webkit-transform: scale(0.999833501948367, 1.000014970213524);
            transform: scale(0.999833501948367, 1.000014970213524);
  }
  37% {
    -webkit-transform: scale(1.000135225951195, 1.000016350718153);
            transform: scale(1.000135225951195, 1.000016350718153);
  }
  38% {
    -webkit-transform: scale(1.00031555507819, 1.000011778605271);
            transform: scale(1.00031555507819, 1.000011778605271);
  }
  39% {
    -webkit-transform: scale(1.000385276149533, 1.000005429970832);
            transform: scale(1.000385276149533, 1.000005429970832);
  }
  40% {
    -webkit-transform: scale(1.00036955135608, 1.000000175788403);
            transform: scale(1.00036955135608, 1.000000175788403);
  }
  41% {
    -webkit-transform: scale(1.000299099745054, 0.999997234930038);
            transform: scale(1.000299099745054, 0.999997234930038);
  }
  42% {
    -webkit-transform: scale(1.000203385122328, 0.999996522266158);
            transform: scale(1.000203385122328, 0.999996522266158);
  }
  43% {
    -webkit-transform: scale(1.000106253265117, 0.999997264764804);
            transform: scale(1.000106253265117, 0.999997264764804);
  }
  44% {
    -webkit-transform: scale(1.000023918238932, 0.999998563845857);
            transform: scale(1.000023918238932, 0.999998563845857);
  }
  45% {
    -webkit-transform: scale(0.999964849826148, 0.999999744931904);
            transform: scale(0.999964849826148, 0.999999744931904);
  }
  46% {
    -webkit-transform: scale(0.999930953553718, 1.000000476269022);
            transform: scale(0.999930953553718, 1.000000476269022);
  }
  47% {
    -webkit-transform: scale(0.999919425875407, 1.000000722388812);
            transform: scale(0.999919425875407, 1.000000722388812);
  }
  48% {
    -webkit-transform: scale(0.999924761163581, 1.000000621989317);
            transform: scale(0.999924761163581, 1.000000621989317);
  }
  49% {
    -webkit-transform: scale(0.999940535092441, 1.000000364142579);
            transform: scale(0.999940535092441, 1.000000364142579);
  }
  50% {
    -webkit-transform: scale(0.99996074901389, 1.000000103935755);
            transform: scale(0.99996074901389, 1.000000103935755);
  }
  51% {
    -webkit-transform: scale(0.999980662109647, 0.999999927251821);
            transform: scale(0.999980662109647, 0.999999927251821);
  }
  52% {
    -webkit-transform: scale(0.999997145282538, 0.999999853998891);
            transform: scale(0.999997145282538, 0.999999853998891);
  }
  53% {
    -webkit-transform: scale(1.000008657088084, 0.999999861387962);
            transform: scale(1.000008657088084, 0.999999861387962);
  }
  54% {
    -webkit-transform: scale(1.000014970213524, 0.999999910625746);
            transform: scale(1.000014970213524, 0.999999910625746);
  }
  55% {
    -webkit-transform: scale(1.000016775086596, 0.999999966780841);
            transform: scale(1.000016775086596, 0.999999966780841);
  }
  56% {
    -webkit-transform: scale(1.000015265384358, 1.000000008427682);
            transform: scale(1.000015265384358, 1.000000008427682);
  }
  57% {
    -webkit-transform: scale(1.000011778605271, 1.000000028536839);
            transform: scale(1.000011778605271, 1.000000028536839);
  }
  58% {
    -webkit-transform: scale(1.000007531821609, 1.000000030270993);
            transform: scale(1.000007531821609, 1.000000030270993);
  }
  59% {
    -webkit-transform: scale(1.000003464072008, 1.000000021355454);
            transform: scale(1.000003464072008, 1.000000021355454);
  }
  60% {
    -webkit-transform: scale(1.000000175788403, 1.000000009501385);
            transform: scale(1.000000175788403, 1.000000009501385);
  }
  61% {
    -webkit-transform: scale(0.999997943086903, 0.99999999989862);
            transform: scale(0.999997943086903, 0.99999999989862);
  }
  62% {
    -webkit-transform: scale(0.999996779924779, 0.999999994661554);
            transform: scale(0.999996779924779, 0.999999994661554);
  }
  63% {
    -webkit-transform: scale(0.999996522266158, 0.999999993527534);
            transform: scale(0.999996522266158, 0.999999993527534);
  }
  64% {
    -webkit-transform: scale(0.999996913355979, 0.999999995014962);
            transform: scale(0.999996913355979, 0.999999995014962);
  }
  65% {
    -webkit-transform: scale(0.999997675912265, 0.999999997456225);
            transform: scale(0.999997675912265, 0.999999997456225);
  }
  66% {
    -webkit-transform: scale(0.999998563845857, 0.999999999625199);
            transform: scale(0.999998563845857, 0.999999999625199);
  }
  67% {
    -webkit-transform: scale(0.999999391872285, 1.000000000937729);
            transform: scale(0.999999391872285, 1.000000000937729);
  }
  68% {
    -webkit-transform: scale(1.000000045503658, 1.000000001352395);
            transform: scale(1.000000045503658, 1.000000001352395);
  }
  69% {
    -webkit-transform: scale(1.000000476269022, 1.000000001139136);
            transform: scale(1.000000476269022, 1.000000001139136);
  }
  70% {
    -webkit-transform: scale(1.000000687807928, 1.000000000650779);
            transform: scale(1.000000687807928, 1.000000000650779);
  }
  71% {
    -webkit-transform: scale(1.000000718100621, 1.000000000170632);
            transform: scale(1.000000718100621, 1.000000000170632);
  }
  72% {
    -webkit-transform: scale(1.000000621989317, 0.999999999851502);
            transform: scale(1.000000621989317, 0.999999999851502);
  }
  73% {
    -webkit-transform: scale(1.000000456728239, 0.999999999724764);
            transform: scale(1.000000456728239, 0.999999999724764);
  }
  74% {
    -webkit-transform: scale(1.00000027190648, 0.999999999744926);
            transform: scale(1.00000027190648, 0.999999999744926);
  }
  75% {
    -webkit-transform: scale(1.000000103935755, 0.999999999839136);
            transform: scale(1.000000103935755, 0.999999999839136);
  }
  76% {
    -webkit-transform: scale(0.999999974491714, 0.999999999943276);
            transform: scale(0.999999974491714, 0.999999999943276);
  }
  77% {
    -webkit-transform: scale(0.999999891858109, 1.000000000018919);
            transform: scale(0.999999891858109, 1.000000000018919);
  }
  78% {
    -webkit-transform: scale(0.999999853998891, 1.000000000054265);
            transform: scale(0.999999853998891, 1.000000000054265);
  }
  79% {
    -webkit-transform: scale(0.999999852290513, 1.000000000055976);
            transform: scale(0.999999852290513, 1.000000000055976);
  }
  80% {
    -webkit-transform: scale(0.999999875091735, 1.000000000038668);
            transform: scale(0.999999875091735, 1.000000000038668);
  }
  81% {
    -webkit-transform: scale(0.999999910625746, 1.000000000016565);
            transform: scale(0.999999910625746, 1.000000000016565);
  }
  82% {
    -webkit-transform: scale(0.999999948934096, 0.999999999999035);
            transform: scale(0.999999948934096, 0.999999999999035);
  }
  83% {
    -webkit-transform: scale(0.999999982891666, 0.999999999989729);
            transform: scale(0.999999982891666, 0.999999999989729);
  }
  84% {
    -webkit-transform: scale(1.000000008427682, 0.999999999987969);
            transform: scale(1.000000008427682, 0.999999999987969);
  }
  85% {
    -webkit-transform: scale(1.000000024178711, 0.999999999990925);
            transform: scale(1.000000024178711, 0.999999999990925);
  }
  86% {
    -webkit-transform: scale(1.000000030817137, 0.999999999995506);
            transform: scale(1.000000030817137, 0.999999999995506);
  }
  87% {
    -webkit-transform: scale(1.000000030270993, 0.999999999999484);
            transform: scale(1.000000030270993, 0.999999999999484);
  }
  88% {
    -webkit-transform: scale(1.000000024997425, 1.000000000001836);
            transform: scale(1.000000024997425, 1.000000000001836);
  }
  89% {
    -webkit-transform: scale(1.000000017409911, 1.000000000002528);
            transform: scale(1.000000017409911, 1.000000000002528);
  }
  90% {
    -webkit-transform: scale(1.000000009501385, 1.000000000002084);
            transform: scale(1.000000009501385, 1.000000000002084);
  }
  91% {
    -webkit-transform: scale(1.000000002659794, 1.000000000001161);
            transform: scale(1.000000002659794, 1.000000000001161);
  }
  92% {
    -webkit-transform: scale(0.999999997642536, 1.000000000000276);
            transform: scale(0.999999997642536, 1.000000000000276);
  }
  93% {
    -webkit-transform: scale(0.999999994661554, 0.9999999999997);
            transform: scale(0.999999994661554, 0.9999999999997);
  }
  94% {
    -webkit-transform: scale(0.999999993528801, 0.999999999999482);
            transform: scale(0.999999993528801, 0.999999999999482);
  }
  95% {
    -webkit-transform: scale(0.999999993818591, 0.999999999999531);
            transform: scale(0.999999993818591, 0.999999999999531);
  }
  96% {
    -webkit-transform: scale(0.999999995014962, 0.999999999999711);
            transform: scale(0.999999995014962, 0.999999999999711);
  }
  97% {
    -webkit-transform: scale(0.999999996625079, 0.999999999999904);
            transform: scale(0.999999996625079, 0.999999999999904);
  }
  98% {
    -webkit-transform: scale(0.999999998251508, 1.000000000000041);
            transform: scale(0.999999998251508, 1.000000000000041);
  }
  99% {
    -webkit-transform: scale(0.999999999625199, 1.000000000000103);
            transform: scale(0.999999999625199, 1.000000000000103);
  }
  100% {
    -webkit-transform: scale(1.000000000606774, 1.000000000000103);
            transform: scale(1.000000000606774, 1.000000000000103);
  }
  to {
    -webkit-transform: scale(1, 1) !important;
            transform: scale(1, 1) !important;
  }
}
.wobble{
  animation-name: wobble;
  animation-fill-mode: forward;
  animation-duration: 4000ms;
  animation-iteration-count: 1;
  animation-timing-function: linear; 
}


@keyframes error {
  0% { transform: translate(0px); }
  25% { transform: translate(5px); }
  50% { transform: translate(-5px); }
  100% { transform: translate(0px); }
}
.ani-error{
  animation-name: error;
  animation-fill-mode: forward;
  animation-duration: 100ms;
  animation-iteration-count: 2;
  animation-timing-function: linear; 
}