
#progress-container-wrap{
  display: flex;-js-display: flex;flex:1;
  margin: 10px auto;
  justify-content: center;
  max-height: 10px;
  width: 100%;
}
@media screen and (min-width: 768px){
  #progress-container-wrap{
    display: flex;-js-display: flex;flex:1;
    margin: 40px;
  }
}
.progress-bar-wrap{
  display: flex;-js-display: flex;flex:1;
  position: relative;
  max-height: 10px;
  width: 100%;
  max-width: 270px;
  z-index: 1;
}
.progress-bar-bg-wrap{
  display: flex;-js-display: flex;flex:1;
  position: absolute;
  z-index: 0;
}
.progression-wrap{
  display: flex;-js-display: flex;flex:1;
  position: relative;
  max-height: 10px;
  z-index: 1;
}
.progress-ring{
  stroke: #000;
}

.progress-ring__circle {
  stroke-dasharray: 10 20;
  transition: all 1s ease-in-out;
}
.progress-ring__circle.no-transition {
  stroke-dasharray: 10 20;
  transition: all 0s ease-in-out;
}
.progress-ring__t-1{
  transition-delay: 1s;
}
.progress-ring__t-0{
  transition-delay: 0s;
}
.progress-ring__line {
  transition: all 1s ease-in-out;
}

.delay1{
  transition-delay: 1s;
}