body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a{
  border:none;
  outline:none;
}
#page{
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

#homePage .main-content{
  padding: 10px;
}

#homePage .header {
  background: #000;
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 10px 20px;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#homePage .header img{
  max-width: 300px;
}
#homePage .sub-header{
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#homePage .sub-header p{
  text-align: center;
  font-weight: 600;
}

#homePage .main-content{
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 900px;
  margin: 40px auto;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

#homePage .content-left{
  max-width: 350px;
}

#homePage .content-left img{
  border: solid 3px #eee;
  max-width: 350px;
  width: 100%;
}

@media screen and (max-width: 767px){
  #homePage .content-left img{
    border: solid 2px #eee;
    max-width: 290px;
    width: 100%;
  }
}

#homePage .content-right{
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0;
  font-weight: 300;
  text-align: center;
}
#homePage .content-right h1{
  font-weight: 200;
}
#homePage .content-right li{
  list-style: none;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 0;
  text-align: left;
}
#homePage .content-right li img{
  max-width: 20px;
  margin-right: 10px;
}
button{
  padding: 10px;
  font-size: 22px;
  border: none;
  -webkit-border-radius: 3px;
          border-radius: 3px;
  max-width: 375px;
  width: 100%;
  -webkit-transition: opacity .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out
}
#homePage .content-right button:hover{
  opacity: .7;
}
#homePage .how-it-works{
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
}
#homePage .how-it-works h2{
  font-weight: 300;
}
#homePage .steps-wrap{
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
#homePage .steps-wrap h3{
  font-weight: 500;
}
#homePage .steps-wrap .step{
  max-width: 270px;
}
#homePage .steps-wrap .step p{
  font-size: 12px;
  font-weight: 200;
}

#homePage .steps-wrap .step img{
  max-width: 100%;
}

#homePage .steps-wrap .step.one img{
  max-width: 80%;
  position: relative;
  top: 1px;
}

#homePage .steps-wrap .step.three img{
  max-width: 75%;
  position: relative;
  top: -3px;
}

#footer-links{
  left: 0;
  width: 100%;
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #222;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 0;
  color: #fff;
  margin-top: 40px;
}

#footer-links.light{
  background: transparent;
  color: #222;
}

#footer-links.light a{
  color: #222;
}

#footer-links a{
  color: #fff;
  text-decoration: none;
}

@media screen and (min-width: 768px){
  #homePage .steps-wrap{
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  #homePage .main-content{
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  #homePage .content-right{
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0 0 0 20px;
    font-weight: 300;
    text-align: left;
  }
}


@media screen and (min-width: 800px){
  #homePage .content-right{
    padding: 0 0 0 60px;
  }
}

@media screen and (min-width: 880px){
  #homePage .content-right{
    padding: 0 0 0 80px;
  }
}

.clearfix::after {
    display: block;
    content: "";
    clear: both;
}


#page{
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; -js-display: flex; -webkit-flex:1 1; -ms-flex:1 1; flex:1 1;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.quiz-wrap{
  width: 100%;
  max-width: 304px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; -js-display: flex; -webkit-flex:1 1; -ms-flex:1 1; flex:1 1;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column
}
.logo-heading{
  max-width: 280px;
  margin-top: 40px;
  display: block;
  margin: 40px auto 20px;
}
.main-btn{
  border: solid 2px;
  padding: 6px 10px;
  -webkit-border-radius: 40px;
          border-radius: 40px;
  background: transparent;
  font-size: 18px;
  width: 280px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
@media screen and (min-width: 768px){  
  .logo-heading{
    max-width: 300px; 
  }
  .quiz-wrap{
    max-width: 744px;
  }
}
@-webkit-keyframes spin {
  from {
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg);
  }
  to {
      -webkit-transform:rotate(360deg);
              transform:rotate(360deg);
  }
}
@keyframes spin {
  from {
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg);
  }
  to {
      -webkit-transform:rotate(360deg);
              transform:rotate(360deg);
  }
}
.spinning{
  -webkit-animation-name: spin;
          animation-name: spin;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; 
}

@-webkit-keyframes spin-in {
  0% { -webkit-transform:rotate(0deg); transform:rotate(0deg); }
  30% { -webkit-transform:rotate(360deg); transform:rotate(360deg); }
  31% { -webkit-transform:rotate(00deg); transform:rotate(00deg); }
  68%, 72% { left: 50px; }
  100% { top: 100px; left: 100%; }
}

@keyframes spin-in {
  0% { -webkit-transform:rotate(0deg); transform:rotate(0deg); }
  30% { -webkit-transform:rotate(360deg); transform:rotate(360deg); }
  31% { -webkit-transform:rotate(00deg); transform:rotate(00deg); }
  68%, 72% { left: 50px; }
  100% { top: 100px; left: 100%; }
}
.spin-in{
  -webkit-animation-name: spin-in;
          animation-name: spin-in;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; 
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  1% {
    -webkit-transform: scale(0.086484529651881, 0.179427456557767);
            transform: scale(0.086484529651881, 0.179427456557767);
  }
  2% {
    -webkit-transform: scale(0.292554252432152, 0.545376716848168);
            transform: scale(0.292554252432152, 0.545376716848168);
  }
  3% {
    -webkit-transform: scale(0.545376716848168, 0.894404964543183);
            transform: scale(0.545376716848168, 0.894404964543183);
  }
  4% {
    -webkit-transform: scale(0.788551391390369, 1.120376016086594);
            transform: scale(0.788551391390369, 1.120376016086594);
  }
  5% {
    -webkit-transform: scale(0.985835941661639, 1.205153326404069);
            transform: scale(0.985835941661639, 1.205153326404069);
  }
  6% {
    -webkit-transform: scale(1.120376016086594, 1.184807461549862);
            transform: scale(1.120376016086594, 1.184807461549862);
  }
  7% {
    -webkit-transform: scale(1.191045818185745, 1.113400777234015);
            transform: scale(1.191045818185745, 1.113400777234015);
  }
  8% {
    -webkit-transform: scale(1.207445230574602, 1.037247338712057);
            transform: scale(1.207445230574602, 1.037247338712057);
  }
  9% {
    -webkit-transform: scale(1.184807461549862, 0.983312126349848);
            transform: scale(1.184807461549862, 0.983312126349848);
  }
  10% {
    -webkit-transform: scale(1.139672272493081, 0.959151493096828);
            transform: scale(1.139672272493081, 0.959151493096828);
  }
  11% {
    -webkit-transform: scale(1.086773838727188, 0.959207005548428);
            transform: scale(1.086773838727188, 0.959207005548428);
  }
  12% {
    -webkit-transform: scale(1.037247338712057, 0.972534530793938);
            transform: scale(1.037247338712057, 0.972534530793938);
  }
  13% {
    -webkit-transform: scale(0.998010256157525, 0.98880159678671);
            transform: scale(0.998010256157525, 0.98880159678671);
  }
  14% {
    -webkit-transform: scale(0.972031419546276, 1.001379435012348);
            transform: scale(0.972031419546276, 1.001379435012348);
  }
  15% {
    -webkit-transform: scale(0.959151493096828, 1.00783265522376);
            transform: scale(0.959151493096828, 1.00783265522376);
  }
  16% {
    -webkit-transform: scale(0.957140100254271, 1.008821093113781);
            transform: scale(0.957140100254271, 1.008821093113781);
  }
  17% {
    -webkit-transform: scale(0.96274018852263, 1.006488198218788);
            transform: scale(0.96274018852263, 1.006488198218788);
  }
  18% {
    -webkit-transform: scale(0.972534530793938, 1.003092956925791);
            transform: scale(0.972534530793938, 1.003092956925791);
  }
  19% {
    -webkit-transform: scale(0.983552526255917, 1.000221414749495);
            transform: scale(0.983552526255917, 1.000221414749495);
  }
  20% {
    -webkit-transform: scale(0.993604544681563, 0.998573305048547);
            transform: scale(0.993604544681563, 0.998573305048547);
  }
  21% {
    -webkit-transform: scale(1.001379435012348, 0.998133854506318);
            transform: scale(1.001379435012348, 0.998133854506318);
  }
  22% {
    -webkit-transform: scale(1.006367469874671, 0.998500975623806);
            transform: scale(1.006367469874671, 0.998500975623806);
  }
  23% {
    -webkit-transform: scale(1.00867876840649, 0.999191084459488);
            transform: scale(1.00867876840649, 0.999191084459488);
  }
  24% {
    -webkit-transform: scale(1.008821093113781, 0.999833501948367);
            transform: scale(1.008821093113781, 0.999833501948367);
  }
  25% {
    -webkit-transform: scale(1.007486437375814, 1.000240340703161);
            transform: scale(1.007486437375814, 1.000240340703161);
  }
  26% {
    -webkit-transform: scale(1.005378103626964, 1.000385276149533);
            transform: scale(1.005378103626964, 1.000385276149533);
  }
  27% {
    -webkit-transform: scale(1.003092956925791, 1.000339232175458);
            transform: scale(1.003092956925791, 1.000339232175458);
  }
  28% {
    -webkit-transform: scale(1.001059752569101, 1.000203385122328);
            transform: scale(1.001059752569101, 1.000203385122328);
  }
  29% {
    -webkit-transform: scale(0.999525049489696, 1.000062534362191);
            transform: scale(0.999525049489696, 1.000062534362191);
  }
  30% {
    -webkit-transform: scale(0.998573305048547, 0.999964849826148);
            transform: scale(0.998573305048547, 0.999964849826148);
  }
  31% {
    -webkit-transform: scale(0.998166624576185, 0.999922697095762);
            transform: scale(0.998166624576185, 0.999922697095762);
  }
  32% {
    -webkit-transform: scale(0.998191241770966, 0.999924761163581);
            transform: scale(0.998191241770966, 0.999924761163581);
  }
  33% {
    -webkit-transform: scale(0.998500975623806, 0.999950419122189);
            transform: scale(0.998500975623806, 0.999950419122189);
  }
  34% {
    -webkit-transform: scale(0.998951615476555, 0.999980662109647);
            transform: scale(0.998951615476555, 0.999980662109647);
  }
  35% {
    -webkit-transform: scale(0.99942365059167, 1.000003563281379);
            transform: scale(0.99942365059167, 1.000003563281379);
  }
  36% {
    -webkit-transform: scale(0.999833501948367, 1.000014970213524);
            transform: scale(0.999833501948367, 1.000014970213524);
  }
  37% {
    -webkit-transform: scale(1.000135225951195, 1.000016350718153);
            transform: scale(1.000135225951195, 1.000016350718153);
  }
  38% {
    -webkit-transform: scale(1.00031555507819, 1.000011778605271);
            transform: scale(1.00031555507819, 1.000011778605271);
  }
  39% {
    -webkit-transform: scale(1.000385276149533, 1.000005429970832);
            transform: scale(1.000385276149533, 1.000005429970832);
  }
  40% {
    -webkit-transform: scale(1.00036955135608, 1.000000175788403);
            transform: scale(1.00036955135608, 1.000000175788403);
  }
  41% {
    -webkit-transform: scale(1.000299099745054, 0.999997234930038);
            transform: scale(1.000299099745054, 0.999997234930038);
  }
  42% {
    -webkit-transform: scale(1.000203385122328, 0.999996522266158);
            transform: scale(1.000203385122328, 0.999996522266158);
  }
  43% {
    -webkit-transform: scale(1.000106253265117, 0.999997264764804);
            transform: scale(1.000106253265117, 0.999997264764804);
  }
  44% {
    -webkit-transform: scale(1.000023918238932, 0.999998563845857);
            transform: scale(1.000023918238932, 0.999998563845857);
  }
  45% {
    -webkit-transform: scale(0.999964849826148, 0.999999744931904);
            transform: scale(0.999964849826148, 0.999999744931904);
  }
  46% {
    -webkit-transform: scale(0.999930953553718, 1.000000476269022);
            transform: scale(0.999930953553718, 1.000000476269022);
  }
  47% {
    -webkit-transform: scale(0.999919425875407, 1.000000722388812);
            transform: scale(0.999919425875407, 1.000000722388812);
  }
  48% {
    -webkit-transform: scale(0.999924761163581, 1.000000621989317);
            transform: scale(0.999924761163581, 1.000000621989317);
  }
  49% {
    -webkit-transform: scale(0.999940535092441, 1.000000364142579);
            transform: scale(0.999940535092441, 1.000000364142579);
  }
  50% {
    -webkit-transform: scale(0.99996074901389, 1.000000103935755);
            transform: scale(0.99996074901389, 1.000000103935755);
  }
  51% {
    -webkit-transform: scale(0.999980662109647, 0.999999927251821);
            transform: scale(0.999980662109647, 0.999999927251821);
  }
  52% {
    -webkit-transform: scale(0.999997145282538, 0.999999853998891);
            transform: scale(0.999997145282538, 0.999999853998891);
  }
  53% {
    -webkit-transform: scale(1.000008657088084, 0.999999861387962);
            transform: scale(1.000008657088084, 0.999999861387962);
  }
  54% {
    -webkit-transform: scale(1.000014970213524, 0.999999910625746);
            transform: scale(1.000014970213524, 0.999999910625746);
  }
  55% {
    -webkit-transform: scale(1.000016775086596, 0.999999966780841);
            transform: scale(1.000016775086596, 0.999999966780841);
  }
  56% {
    -webkit-transform: scale(1.000015265384358, 1.000000008427682);
            transform: scale(1.000015265384358, 1.000000008427682);
  }
  57% {
    -webkit-transform: scale(1.000011778605271, 1.000000028536839);
            transform: scale(1.000011778605271, 1.000000028536839);
  }
  58% {
    -webkit-transform: scale(1.000007531821609, 1.000000030270993);
            transform: scale(1.000007531821609, 1.000000030270993);
  }
  59% {
    -webkit-transform: scale(1.000003464072008, 1.000000021355454);
            transform: scale(1.000003464072008, 1.000000021355454);
  }
  60% {
    -webkit-transform: scale(1.000000175788403, 1.000000009501385);
            transform: scale(1.000000175788403, 1.000000009501385);
  }
  61% {
    -webkit-transform: scale(0.999997943086903, 0.99999999989862);
            transform: scale(0.999997943086903, 0.99999999989862);
  }
  62% {
    -webkit-transform: scale(0.999996779924779, 0.999999994661554);
            transform: scale(0.999996779924779, 0.999999994661554);
  }
  63% {
    -webkit-transform: scale(0.999996522266158, 0.999999993527534);
            transform: scale(0.999996522266158, 0.999999993527534);
  }
  64% {
    -webkit-transform: scale(0.999996913355979, 0.999999995014962);
            transform: scale(0.999996913355979, 0.999999995014962);
  }
  65% {
    -webkit-transform: scale(0.999997675912265, 0.999999997456225);
            transform: scale(0.999997675912265, 0.999999997456225);
  }
  66% {
    -webkit-transform: scale(0.999998563845857, 0.999999999625199);
            transform: scale(0.999998563845857, 0.999999999625199);
  }
  67% {
    -webkit-transform: scale(0.999999391872285, 1.000000000937729);
            transform: scale(0.999999391872285, 1.000000000937729);
  }
  68% {
    -webkit-transform: scale(1.000000045503658, 1.000000001352395);
            transform: scale(1.000000045503658, 1.000000001352395);
  }
  69% {
    -webkit-transform: scale(1.000000476269022, 1.000000001139136);
            transform: scale(1.000000476269022, 1.000000001139136);
  }
  70% {
    -webkit-transform: scale(1.000000687807928, 1.000000000650779);
            transform: scale(1.000000687807928, 1.000000000650779);
  }
  71% {
    -webkit-transform: scale(1.000000718100621, 1.000000000170632);
            transform: scale(1.000000718100621, 1.000000000170632);
  }
  72% {
    -webkit-transform: scale(1.000000621989317, 0.999999999851502);
            transform: scale(1.000000621989317, 0.999999999851502);
  }
  73% {
    -webkit-transform: scale(1.000000456728239, 0.999999999724764);
            transform: scale(1.000000456728239, 0.999999999724764);
  }
  74% {
    -webkit-transform: scale(1.00000027190648, 0.999999999744926);
            transform: scale(1.00000027190648, 0.999999999744926);
  }
  75% {
    -webkit-transform: scale(1.000000103935755, 0.999999999839136);
            transform: scale(1.000000103935755, 0.999999999839136);
  }
  76% {
    -webkit-transform: scale(0.999999974491714, 0.999999999943276);
            transform: scale(0.999999974491714, 0.999999999943276);
  }
  77% {
    -webkit-transform: scale(0.999999891858109, 1.000000000018919);
            transform: scale(0.999999891858109, 1.000000000018919);
  }
  78% {
    -webkit-transform: scale(0.999999853998891, 1.000000000054265);
            transform: scale(0.999999853998891, 1.000000000054265);
  }
  79% {
    -webkit-transform: scale(0.999999852290513, 1.000000000055976);
            transform: scale(0.999999852290513, 1.000000000055976);
  }
  80% {
    -webkit-transform: scale(0.999999875091735, 1.000000000038668);
            transform: scale(0.999999875091735, 1.000000000038668);
  }
  81% {
    -webkit-transform: scale(0.999999910625746, 1.000000000016565);
            transform: scale(0.999999910625746, 1.000000000016565);
  }
  82% {
    -webkit-transform: scale(0.999999948934096, 0.999999999999035);
            transform: scale(0.999999948934096, 0.999999999999035);
  }
  83% {
    -webkit-transform: scale(0.999999982891666, 0.999999999989729);
            transform: scale(0.999999982891666, 0.999999999989729);
  }
  84% {
    -webkit-transform: scale(1.000000008427682, 0.999999999987969);
            transform: scale(1.000000008427682, 0.999999999987969);
  }
  85% {
    -webkit-transform: scale(1.000000024178711, 0.999999999990925);
            transform: scale(1.000000024178711, 0.999999999990925);
  }
  86% {
    -webkit-transform: scale(1.000000030817137, 0.999999999995506);
            transform: scale(1.000000030817137, 0.999999999995506);
  }
  87% {
    -webkit-transform: scale(1.000000030270993, 0.999999999999484);
            transform: scale(1.000000030270993, 0.999999999999484);
  }
  88% {
    -webkit-transform: scale(1.000000024997425, 1.000000000001836);
            transform: scale(1.000000024997425, 1.000000000001836);
  }
  89% {
    -webkit-transform: scale(1.000000017409911, 1.000000000002528);
            transform: scale(1.000000017409911, 1.000000000002528);
  }
  90% {
    -webkit-transform: scale(1.000000009501385, 1.000000000002084);
            transform: scale(1.000000009501385, 1.000000000002084);
  }
  91% {
    -webkit-transform: scale(1.000000002659794, 1.000000000001161);
            transform: scale(1.000000002659794, 1.000000000001161);
  }
  92% {
    -webkit-transform: scale(0.999999997642536, 1.000000000000276);
            transform: scale(0.999999997642536, 1.000000000000276);
  }
  93% {
    -webkit-transform: scale(0.999999994661554, 0.9999999999997);
            transform: scale(0.999999994661554, 0.9999999999997);
  }
  94% {
    -webkit-transform: scale(0.999999993528801, 0.999999999999482);
            transform: scale(0.999999993528801, 0.999999999999482);
  }
  95% {
    -webkit-transform: scale(0.999999993818591, 0.999999999999531);
            transform: scale(0.999999993818591, 0.999999999999531);
  }
  96% {
    -webkit-transform: scale(0.999999995014962, 0.999999999999711);
            transform: scale(0.999999995014962, 0.999999999999711);
  }
  97% {
    -webkit-transform: scale(0.999999996625079, 0.999999999999904);
            transform: scale(0.999999996625079, 0.999999999999904);
  }
  98% {
    -webkit-transform: scale(0.999999998251508, 1.000000000000041);
            transform: scale(0.999999998251508, 1.000000000000041);
  }
  99% {
    -webkit-transform: scale(0.999999999625199, 1.000000000000103);
            transform: scale(0.999999999625199, 1.000000000000103);
  }
  100% {
    -webkit-transform: scale(1.000000000606774, 1.000000000000103);
            transform: scale(1.000000000606774, 1.000000000000103);
  }
  to {
    -webkit-transform: scale(1, 1) !important;
            transform: scale(1, 1) !important;
  }
}

@keyframes wobble {
  from {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  1% {
    -webkit-transform: scale(0.086484529651881, 0.179427456557767);
            transform: scale(0.086484529651881, 0.179427456557767);
  }
  2% {
    -webkit-transform: scale(0.292554252432152, 0.545376716848168);
            transform: scale(0.292554252432152, 0.545376716848168);
  }
  3% {
    -webkit-transform: scale(0.545376716848168, 0.894404964543183);
            transform: scale(0.545376716848168, 0.894404964543183);
  }
  4% {
    -webkit-transform: scale(0.788551391390369, 1.120376016086594);
            transform: scale(0.788551391390369, 1.120376016086594);
  }
  5% {
    -webkit-transform: scale(0.985835941661639, 1.205153326404069);
            transform: scale(0.985835941661639, 1.205153326404069);
  }
  6% {
    -webkit-transform: scale(1.120376016086594, 1.184807461549862);
            transform: scale(1.120376016086594, 1.184807461549862);
  }
  7% {
    -webkit-transform: scale(1.191045818185745, 1.113400777234015);
            transform: scale(1.191045818185745, 1.113400777234015);
  }
  8% {
    -webkit-transform: scale(1.207445230574602, 1.037247338712057);
            transform: scale(1.207445230574602, 1.037247338712057);
  }
  9% {
    -webkit-transform: scale(1.184807461549862, 0.983312126349848);
            transform: scale(1.184807461549862, 0.983312126349848);
  }
  10% {
    -webkit-transform: scale(1.139672272493081, 0.959151493096828);
            transform: scale(1.139672272493081, 0.959151493096828);
  }
  11% {
    -webkit-transform: scale(1.086773838727188, 0.959207005548428);
            transform: scale(1.086773838727188, 0.959207005548428);
  }
  12% {
    -webkit-transform: scale(1.037247338712057, 0.972534530793938);
            transform: scale(1.037247338712057, 0.972534530793938);
  }
  13% {
    -webkit-transform: scale(0.998010256157525, 0.98880159678671);
            transform: scale(0.998010256157525, 0.98880159678671);
  }
  14% {
    -webkit-transform: scale(0.972031419546276, 1.001379435012348);
            transform: scale(0.972031419546276, 1.001379435012348);
  }
  15% {
    -webkit-transform: scale(0.959151493096828, 1.00783265522376);
            transform: scale(0.959151493096828, 1.00783265522376);
  }
  16% {
    -webkit-transform: scale(0.957140100254271, 1.008821093113781);
            transform: scale(0.957140100254271, 1.008821093113781);
  }
  17% {
    -webkit-transform: scale(0.96274018852263, 1.006488198218788);
            transform: scale(0.96274018852263, 1.006488198218788);
  }
  18% {
    -webkit-transform: scale(0.972534530793938, 1.003092956925791);
            transform: scale(0.972534530793938, 1.003092956925791);
  }
  19% {
    -webkit-transform: scale(0.983552526255917, 1.000221414749495);
            transform: scale(0.983552526255917, 1.000221414749495);
  }
  20% {
    -webkit-transform: scale(0.993604544681563, 0.998573305048547);
            transform: scale(0.993604544681563, 0.998573305048547);
  }
  21% {
    -webkit-transform: scale(1.001379435012348, 0.998133854506318);
            transform: scale(1.001379435012348, 0.998133854506318);
  }
  22% {
    -webkit-transform: scale(1.006367469874671, 0.998500975623806);
            transform: scale(1.006367469874671, 0.998500975623806);
  }
  23% {
    -webkit-transform: scale(1.00867876840649, 0.999191084459488);
            transform: scale(1.00867876840649, 0.999191084459488);
  }
  24% {
    -webkit-transform: scale(1.008821093113781, 0.999833501948367);
            transform: scale(1.008821093113781, 0.999833501948367);
  }
  25% {
    -webkit-transform: scale(1.007486437375814, 1.000240340703161);
            transform: scale(1.007486437375814, 1.000240340703161);
  }
  26% {
    -webkit-transform: scale(1.005378103626964, 1.000385276149533);
            transform: scale(1.005378103626964, 1.000385276149533);
  }
  27% {
    -webkit-transform: scale(1.003092956925791, 1.000339232175458);
            transform: scale(1.003092956925791, 1.000339232175458);
  }
  28% {
    -webkit-transform: scale(1.001059752569101, 1.000203385122328);
            transform: scale(1.001059752569101, 1.000203385122328);
  }
  29% {
    -webkit-transform: scale(0.999525049489696, 1.000062534362191);
            transform: scale(0.999525049489696, 1.000062534362191);
  }
  30% {
    -webkit-transform: scale(0.998573305048547, 0.999964849826148);
            transform: scale(0.998573305048547, 0.999964849826148);
  }
  31% {
    -webkit-transform: scale(0.998166624576185, 0.999922697095762);
            transform: scale(0.998166624576185, 0.999922697095762);
  }
  32% {
    -webkit-transform: scale(0.998191241770966, 0.999924761163581);
            transform: scale(0.998191241770966, 0.999924761163581);
  }
  33% {
    -webkit-transform: scale(0.998500975623806, 0.999950419122189);
            transform: scale(0.998500975623806, 0.999950419122189);
  }
  34% {
    -webkit-transform: scale(0.998951615476555, 0.999980662109647);
            transform: scale(0.998951615476555, 0.999980662109647);
  }
  35% {
    -webkit-transform: scale(0.99942365059167, 1.000003563281379);
            transform: scale(0.99942365059167, 1.000003563281379);
  }
  36% {
    -webkit-transform: scale(0.999833501948367, 1.000014970213524);
            transform: scale(0.999833501948367, 1.000014970213524);
  }
  37% {
    -webkit-transform: scale(1.000135225951195, 1.000016350718153);
            transform: scale(1.000135225951195, 1.000016350718153);
  }
  38% {
    -webkit-transform: scale(1.00031555507819, 1.000011778605271);
            transform: scale(1.00031555507819, 1.000011778605271);
  }
  39% {
    -webkit-transform: scale(1.000385276149533, 1.000005429970832);
            transform: scale(1.000385276149533, 1.000005429970832);
  }
  40% {
    -webkit-transform: scale(1.00036955135608, 1.000000175788403);
            transform: scale(1.00036955135608, 1.000000175788403);
  }
  41% {
    -webkit-transform: scale(1.000299099745054, 0.999997234930038);
            transform: scale(1.000299099745054, 0.999997234930038);
  }
  42% {
    -webkit-transform: scale(1.000203385122328, 0.999996522266158);
            transform: scale(1.000203385122328, 0.999996522266158);
  }
  43% {
    -webkit-transform: scale(1.000106253265117, 0.999997264764804);
            transform: scale(1.000106253265117, 0.999997264764804);
  }
  44% {
    -webkit-transform: scale(1.000023918238932, 0.999998563845857);
            transform: scale(1.000023918238932, 0.999998563845857);
  }
  45% {
    -webkit-transform: scale(0.999964849826148, 0.999999744931904);
            transform: scale(0.999964849826148, 0.999999744931904);
  }
  46% {
    -webkit-transform: scale(0.999930953553718, 1.000000476269022);
            transform: scale(0.999930953553718, 1.000000476269022);
  }
  47% {
    -webkit-transform: scale(0.999919425875407, 1.000000722388812);
            transform: scale(0.999919425875407, 1.000000722388812);
  }
  48% {
    -webkit-transform: scale(0.999924761163581, 1.000000621989317);
            transform: scale(0.999924761163581, 1.000000621989317);
  }
  49% {
    -webkit-transform: scale(0.999940535092441, 1.000000364142579);
            transform: scale(0.999940535092441, 1.000000364142579);
  }
  50% {
    -webkit-transform: scale(0.99996074901389, 1.000000103935755);
            transform: scale(0.99996074901389, 1.000000103935755);
  }
  51% {
    -webkit-transform: scale(0.999980662109647, 0.999999927251821);
            transform: scale(0.999980662109647, 0.999999927251821);
  }
  52% {
    -webkit-transform: scale(0.999997145282538, 0.999999853998891);
            transform: scale(0.999997145282538, 0.999999853998891);
  }
  53% {
    -webkit-transform: scale(1.000008657088084, 0.999999861387962);
            transform: scale(1.000008657088084, 0.999999861387962);
  }
  54% {
    -webkit-transform: scale(1.000014970213524, 0.999999910625746);
            transform: scale(1.000014970213524, 0.999999910625746);
  }
  55% {
    -webkit-transform: scale(1.000016775086596, 0.999999966780841);
            transform: scale(1.000016775086596, 0.999999966780841);
  }
  56% {
    -webkit-transform: scale(1.000015265384358, 1.000000008427682);
            transform: scale(1.000015265384358, 1.000000008427682);
  }
  57% {
    -webkit-transform: scale(1.000011778605271, 1.000000028536839);
            transform: scale(1.000011778605271, 1.000000028536839);
  }
  58% {
    -webkit-transform: scale(1.000007531821609, 1.000000030270993);
            transform: scale(1.000007531821609, 1.000000030270993);
  }
  59% {
    -webkit-transform: scale(1.000003464072008, 1.000000021355454);
            transform: scale(1.000003464072008, 1.000000021355454);
  }
  60% {
    -webkit-transform: scale(1.000000175788403, 1.000000009501385);
            transform: scale(1.000000175788403, 1.000000009501385);
  }
  61% {
    -webkit-transform: scale(0.999997943086903, 0.99999999989862);
            transform: scale(0.999997943086903, 0.99999999989862);
  }
  62% {
    -webkit-transform: scale(0.999996779924779, 0.999999994661554);
            transform: scale(0.999996779924779, 0.999999994661554);
  }
  63% {
    -webkit-transform: scale(0.999996522266158, 0.999999993527534);
            transform: scale(0.999996522266158, 0.999999993527534);
  }
  64% {
    -webkit-transform: scale(0.999996913355979, 0.999999995014962);
            transform: scale(0.999996913355979, 0.999999995014962);
  }
  65% {
    -webkit-transform: scale(0.999997675912265, 0.999999997456225);
            transform: scale(0.999997675912265, 0.999999997456225);
  }
  66% {
    -webkit-transform: scale(0.999998563845857, 0.999999999625199);
            transform: scale(0.999998563845857, 0.999999999625199);
  }
  67% {
    -webkit-transform: scale(0.999999391872285, 1.000000000937729);
            transform: scale(0.999999391872285, 1.000000000937729);
  }
  68% {
    -webkit-transform: scale(1.000000045503658, 1.000000001352395);
            transform: scale(1.000000045503658, 1.000000001352395);
  }
  69% {
    -webkit-transform: scale(1.000000476269022, 1.000000001139136);
            transform: scale(1.000000476269022, 1.000000001139136);
  }
  70% {
    -webkit-transform: scale(1.000000687807928, 1.000000000650779);
            transform: scale(1.000000687807928, 1.000000000650779);
  }
  71% {
    -webkit-transform: scale(1.000000718100621, 1.000000000170632);
            transform: scale(1.000000718100621, 1.000000000170632);
  }
  72% {
    -webkit-transform: scale(1.000000621989317, 0.999999999851502);
            transform: scale(1.000000621989317, 0.999999999851502);
  }
  73% {
    -webkit-transform: scale(1.000000456728239, 0.999999999724764);
            transform: scale(1.000000456728239, 0.999999999724764);
  }
  74% {
    -webkit-transform: scale(1.00000027190648, 0.999999999744926);
            transform: scale(1.00000027190648, 0.999999999744926);
  }
  75% {
    -webkit-transform: scale(1.000000103935755, 0.999999999839136);
            transform: scale(1.000000103935755, 0.999999999839136);
  }
  76% {
    -webkit-transform: scale(0.999999974491714, 0.999999999943276);
            transform: scale(0.999999974491714, 0.999999999943276);
  }
  77% {
    -webkit-transform: scale(0.999999891858109, 1.000000000018919);
            transform: scale(0.999999891858109, 1.000000000018919);
  }
  78% {
    -webkit-transform: scale(0.999999853998891, 1.000000000054265);
            transform: scale(0.999999853998891, 1.000000000054265);
  }
  79% {
    -webkit-transform: scale(0.999999852290513, 1.000000000055976);
            transform: scale(0.999999852290513, 1.000000000055976);
  }
  80% {
    -webkit-transform: scale(0.999999875091735, 1.000000000038668);
            transform: scale(0.999999875091735, 1.000000000038668);
  }
  81% {
    -webkit-transform: scale(0.999999910625746, 1.000000000016565);
            transform: scale(0.999999910625746, 1.000000000016565);
  }
  82% {
    -webkit-transform: scale(0.999999948934096, 0.999999999999035);
            transform: scale(0.999999948934096, 0.999999999999035);
  }
  83% {
    -webkit-transform: scale(0.999999982891666, 0.999999999989729);
            transform: scale(0.999999982891666, 0.999999999989729);
  }
  84% {
    -webkit-transform: scale(1.000000008427682, 0.999999999987969);
            transform: scale(1.000000008427682, 0.999999999987969);
  }
  85% {
    -webkit-transform: scale(1.000000024178711, 0.999999999990925);
            transform: scale(1.000000024178711, 0.999999999990925);
  }
  86% {
    -webkit-transform: scale(1.000000030817137, 0.999999999995506);
            transform: scale(1.000000030817137, 0.999999999995506);
  }
  87% {
    -webkit-transform: scale(1.000000030270993, 0.999999999999484);
            transform: scale(1.000000030270993, 0.999999999999484);
  }
  88% {
    -webkit-transform: scale(1.000000024997425, 1.000000000001836);
            transform: scale(1.000000024997425, 1.000000000001836);
  }
  89% {
    -webkit-transform: scale(1.000000017409911, 1.000000000002528);
            transform: scale(1.000000017409911, 1.000000000002528);
  }
  90% {
    -webkit-transform: scale(1.000000009501385, 1.000000000002084);
            transform: scale(1.000000009501385, 1.000000000002084);
  }
  91% {
    -webkit-transform: scale(1.000000002659794, 1.000000000001161);
            transform: scale(1.000000002659794, 1.000000000001161);
  }
  92% {
    -webkit-transform: scale(0.999999997642536, 1.000000000000276);
            transform: scale(0.999999997642536, 1.000000000000276);
  }
  93% {
    -webkit-transform: scale(0.999999994661554, 0.9999999999997);
            transform: scale(0.999999994661554, 0.9999999999997);
  }
  94% {
    -webkit-transform: scale(0.999999993528801, 0.999999999999482);
            transform: scale(0.999999993528801, 0.999999999999482);
  }
  95% {
    -webkit-transform: scale(0.999999993818591, 0.999999999999531);
            transform: scale(0.999999993818591, 0.999999999999531);
  }
  96% {
    -webkit-transform: scale(0.999999995014962, 0.999999999999711);
            transform: scale(0.999999995014962, 0.999999999999711);
  }
  97% {
    -webkit-transform: scale(0.999999996625079, 0.999999999999904);
            transform: scale(0.999999996625079, 0.999999999999904);
  }
  98% {
    -webkit-transform: scale(0.999999998251508, 1.000000000000041);
            transform: scale(0.999999998251508, 1.000000000000041);
  }
  99% {
    -webkit-transform: scale(0.999999999625199, 1.000000000000103);
            transform: scale(0.999999999625199, 1.000000000000103);
  }
  100% {
    -webkit-transform: scale(1.000000000606774, 1.000000000000103);
            transform: scale(1.000000000606774, 1.000000000000103);
  }
  to {
    -webkit-transform: scale(1, 1) !important;
            transform: scale(1, 1) !important;
  }
}
.wobble{
  -webkit-animation-name: wobble;
          animation-name: wobble;
  -webkit-animation-fill-mode: forward;
          animation-fill-mode: forward;
  -webkit-animation-duration: 4000ms;
          animation-duration: 4000ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; 
}


@-webkit-keyframes error {
  0% { -webkit-transform: translate(0px); transform: translate(0px); }
  25% { -webkit-transform: translate(5px); transform: translate(5px); }
  50% { -webkit-transform: translate(-5px); transform: translate(-5px); }
  100% { -webkit-transform: translate(0px); transform: translate(0px); }
}


@keyframes error {
  0% { -webkit-transform: translate(0px); transform: translate(0px); }
  25% { -webkit-transform: translate(5px); transform: translate(5px); }
  50% { -webkit-transform: translate(-5px); transform: translate(-5px); }
  100% { -webkit-transform: translate(0px); transform: translate(0px); }
}
.ani-error{
  -webkit-animation-name: error;
          animation-name: error;
  -webkit-animation-fill-mode: forward;
          animation-fill-mode: forward;
  -webkit-animation-duration: 100ms;
          animation-duration: 100ms;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; 
}
.question-container{
  padding: 10px 20px 30px;
  width: 100%;
  max-width: 260px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-border-radius: 10px;
          border-radius: 10px;
  border: solid 2px;
  align-items: center;
  text-align: center;
  margin-top: 160px;
  margin-bottom: 100px;
  font-size: 23px;
  font-weight: 200;
  position: absolute;
  top: 0
}
.question-container-inner{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  align-items: center
}
.question-container.one .question-container-inner{
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.question-container.three .question-container-inner,
.question-container.four .question-container-inner,
.question-container.seven .question-container-inner,
.question-container.five .question-container-inner{
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.question-container.three, .question-container.four, .question-container.five,  .question-container.seven{
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-bottom: 60px;
}
.question-container p{
  margin-bottom: 9px;
}
.question-container hr{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #61cbd6;
  margin: 1em 0;
  padding: 0; 
  width: 300px;
}
.quiz-slide-enter {
  opacity: 0.01;
  -webkit-transform: scale(0.9) translateX(100px);
      -ms-transform: scale(0.9) translateX(100px);
          transform: scale(0.9) translateX(100px);
  -webkit-transition: all 1000ms ease-out;
  -o-transition: all 1000ms ease-out;
  transition: all 1000ms ease-out;
}
.quiz-slide-enter-active {
  opacity: 1;
  -webkit-transform: scale(1) translateX(0%);
      -ms-transform: scale(1) translateX(0%);
          transform: scale(1) translateX(0%);
  -webkit-transition: all 1000ms ease-out;
  -o-transition: all 1000ms ease-out;
  transition: all 1000ms ease-out;
}
.quiz-slide-exit {
  opacity: 1;
  -webkit-transform: scale(1) translateX(0%);
      -ms-transform: scale(1) translateX(0%);
          transform: scale(1) translateX(0%);
}
.quiz-slide-exit-active {
  opacity: 0.01;
  -webkit-transform: scale(0.9) translateX(-100px);
      -ms-transform: scale(0.9) translateX(-100px);
          transform: scale(0.9) translateX(-100px);
  -webkit-transition: all 1000ms ease-out;
  -o-transition: all 1000ms ease-out;
  transition: all 1000ms ease-out;
}
.question-footer-text{
  font-size: 12px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0)
}
.how-many-cats{
    margin: 30px 0;
}
.how-many-cats button:hover{
    background: #61cbd6;
    color: #fff !important;
}
.breed-selector{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 125px;  
}

.breed-selector button:first-child{
  margin-top: 20px;
}

.weight-selector{
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  left: -12px;
  min-width: 200px;  
}
.bday-selector{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  left: -12px;
  min-width: 200px;  
}

.breed-selector button{
  margin-bottom: 20px;
}

.env-selector{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center
}

.env-selector button{
  margin: 20px 10px 10px 10px
}

.next-six{
  margin-bottom: 30px
}
.eight .question-container-inner{
  padding: 20px 0;
}
.completed-wrap{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.completed-wrap .bag,
.completed-wrap .bags{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}
.completed-wrap .bag img,
.completed-wrap .bags img{
  max-width: 100px;
  margin-top: 30px;
  height: auto;
}
.completed-wrap .copy .title{
  font-size: 24px;
  border-bottom: solid 1px;
  display: inline;
}
.completed-wrap .copy .desc{
  font-size: 18px;
}
.question-container.two .ani-error.total3{
  margin-top: 290px;
}
.question-container.two .ani-error.total1{
  margin-top: 112px;
}
.question-container.two .ani-error.total1{
  top: 140px;
}
.question-container.two .ani-error.total2{
  top: 228px;
}
.question-container.three .ani-error.total2{
  margin-top: 185px;
  top: 218px;
}
.question-container.three .ani-error.total3{
  margin-top: 265px;
}
.question-container.four .ani-error.total2{
  margin-top: 285px;
  top: 318px;
}
.question-container.four .ani-error.total3{
  margin-top: 425px;
}
.question-container.five .ani-error.total2{
  margin-top: 270px;
  top: 285px;
}
.question-container.five .ani-error.total3{
  margin-top: 390px;
}
.question-container.six .ani-error{
  margin-left: 18px;
}
.question-container.seven .ani-error.total2{
  margin-top: 220px;
  top: 235px;
}
.question-container.seven .ani-error.total3{
  margin-top: 315px;
}
#GoBack{
  position: absolute;
  z-index: 9999;
  left: 0px;
  bottom: -50px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;
  cursor: pointer;
}
#GoBack img{
  max-width: 25px;
}
@media screen and (max-width: 767px){  
  .two .bottom-text{
    margin-top: 0
  }
  .question-container.two .ani-error{
    margin-top: 203px;
  }
  .env-selector button.last{
    margin-right: 0px !important;
  }
  .question-container input,
  .question-container button
  {
    max-width: 210px !important;
  }
  .question-container.six{
    padding-top: 20px;
  }
  .breed-selector.bottom button:first-child{
    margin-top: 0
  }
  .env-selector button.last{
    margin: 20px 10px 20px 10px
  }
}
@media screen and (min-width: 768px){  
  .question-container{
    padding-bottom: 60px;
  }
  .question-container.two .ani-error.total1{
    margin-top: 68px;
  }
  .question-container.two .ani-error.total2{
    margin-top: 113px;
  }
  .question-container.two .ani-error.total3{
    margin-top: 155px;
  }
  .question-container.two{
    padding-top: 20px;
  }
  .question-container.three,
  .question-container.four,
  .question-container.seven,
  .question-container.five{
    padding-bottom: 80px;
  }
  .question-container.six{
    padding-bottom: 20px;
  }
  .question-container.eight{
    padding-bottom: 60px;
  }
  .eight .question-container-inner{
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .question-footer-text{
    margin-top: 80px;
    font-size: 16px;
    bottom: 20px;
    text-align: unset;
  }
  .question-container-inner{
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    max-width: 700px;
  }
  .question-container{
    padding: 60px 20px 40px;
    max-width: 700px;
    margin-top: 210px;
  }
  .question-container.one .main-btn{
    width: 220px;
    margin-right: 10px;
  }
  .weight-selector{
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    position: relative;
    left: -12px;
    min-width: 270px;  
  }
  .bday-selector{
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    position: relative;
    left: -12px;
    min-width: 125px;  
  }
  .bday-selector .quiz_dropdown:first-child{
    margin-right: 5px;
    margin-left: 20px;
  }
  .breed-selector{
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .breed-selector button{
    margin: 20px;
  }
  .env-selector{
    margin: 20px 0;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .next-six{
    margin-bottom: 60px
  }
  .completed-wrap{
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .completed-wrap .copy{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;-js-display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 60%;
    text-align: left;
  }
  .completed-wrap .bag img,
  .completed-wrap .bags img{
    max-width: 200px;
    position: relative;
    top: 10px;
    margin-bottom: 45px
  }
  .completed-wrap .copy .title{
    font-size: 32px;
  }
  .completed-wrap .copy .desc{
    font-size: 24px;
  }
  .completed-wrap .copy button{
    margin-top: 25px;
  }
  .question-container .ani-error.total2{
    top: 148px;
  }
  .question-container.two .ani-error.total2{
    top: 148px;
  }
  .question-container.three .ani-error{
    top: 148px;
  }
  .question-container.three .ani-error.total3{
    margin-top: 180px;
    top: 268px;
  }
  .question-container.four .ani-error{
    margin-top: 110px;
  }
  .question-container .ani-error.total1{
    top: 105px;
  }
  .question-container.four .ani-error.total2{
    margin-top: 225px;
  }
  .question-container.four .ani-error.total3{
    margin-top: 335px;
  }
  .question-container.three .ani-error.total2{
    margin-top: 125px;
  }  
  .question-container.five .ani-error.total2{
    margin-top: 165px;
    top: 238px;
  }
  .question-container.five .ani-error.total3{
    margin-top: 235px;
  }
  .question-container.six .ani-error{
    margin-left: 120px !important;
  }
  .question-container.seven .ani-error.total2{
    margin-top: 165px;
  }
  .question-container.seven .ani-error.total3{
    margin-top: 235px;
  }
}

#progress-container-wrap{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;-webkit-flex:1 1;-ms-flex:1 1;flex:1 1;
  margin: 10px auto;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-height: 10px;
  width: 100%;
}
@media screen and (min-width: 768px){
  #progress-container-wrap{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;-js-display: flex;-webkit-flex:1 1;-ms-flex:1 1;flex:1 1;
    margin: 40px;
  }
}
.progress-bar-wrap{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;-webkit-flex:1 1;-ms-flex:1 1;flex:1 1;
  position: relative;
  max-height: 10px;
  width: 100%;
  max-width: 270px;
  z-index: 1;
}
.progress-bar-bg-wrap{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;-webkit-flex:1 1;-ms-flex:1 1;flex:1 1;
  position: absolute;
  z-index: 0;
}
.progression-wrap{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;-webkit-flex:1 1;-ms-flex:1 1;flex:1 1;
  position: relative;
  max-height: 10px;
  z-index: 1;
}
.progress-ring{
  stroke: #000;
}

.progress-ring__circle {
  stroke-dasharray: 10 20;
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.progress-ring__circle.no-transition {
  stroke-dasharray: 10 20;
  -webkit-transition: all 0s ease-in-out;
  -o-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.progress-ring__t-1{
  -webkit-transition-delay: 1s;
       -o-transition-delay: 1s;
          transition-delay: 1s;
}
.progress-ring__t-0{
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}
.progress-ring__line {
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.delay1{
  -webkit-transition-delay: 1s;
       -o-transition-delay: 1s;
          transition-delay: 1s;
}
/* FORMS */
.form-wrap{
  font-weight: 600
}

/* INPUT OUTER WRAP */
.input-outer-wrap{
  display: inline-block;
  position: relative;
  margin: 20px 10px 5px 0;
  
}
.input-label{
  position: absolute;
  -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
          transform-origin: top left;
  z-index: 0;
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  -o-transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  font-size: 24px;
  bottom: 3px;
}
.input-element-underline-bg{
  opacity: .8;
  position: absolute;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  height: 1px;
  width: 100%;
  margin: auto;
  bottom: 0;
  left: 0
}
.input-element-underline{
  -webkit-transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -o-transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 1px;
  width: 0;
  margin: auto;
}
.input-element{
  background: transparent;
  border: 0;
  position: relative;
  z-index: 10;
  font-size: 24px;
}
/* REMOVE OUTLINE */
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

@media screen and (max-width: 500px){
  .input-outer-wrap.wide-mobile{
    width: 100% !important;
    text-align: left;
  } 
  .input-element{
  } 
}
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.loading {
  /* width: 13px;
  height: 13px; */
  margin-top: 20px;
  font-size: 32px;
  color: #fff;
  -webkit-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}

/* The switch - the box around the slider */
.switch-wrap{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;-js-display: flex;-webkit-flex:1 1;-ms-flex:1 1;flex:1 1;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.switch-wrap span{
  font-size: 18px;
}
@media screen and (max-width: 676px){
  .switch-wrap{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;-js-display: flex;-webkit-flex:1 1;-ms-flex:1 1;flex:1 1;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
  }  
}
.switch {
  position: relative;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #fe6d2c;
}

input:focus + .slider {
  -webkit-box-shadow: 0 0 1px #fe6d2c;
          box-shadow: 0 0 1px #fe6d2c;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  -webkit-border-radius: 34px;
          border-radius: 34px;
}

.slider.round:before {
  -webkit-border-radius: 50%;
          border-radius: 50%;
}

button{
  cursor: pointer;
}

.settings-select{
  background: rgba(255,255,255,0.3);
  border: none;
  padding: 5px;
  color: #fff;
}

/* Dropdowns */
.quiz_dropdown{
  font-size: 13px;
  padding: 10px 8px 10px 14px;
  background: #fff;
  border: 1px solid #61cbd6;
  -webkit-border-radius: 6px;
          border-radius: 6px;
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  position: relative;
}

.quiz_dropdown .select{
  width: 120%;
  background:url('/images/dropdown.png') no-repeat;
  background-position:80% center;
  -webkit-background-size: 15px 15px;
          background-size: 15px 15px;
}

.quiz_dropdown .select select{
  background: transparent;
  line-height: 1;
  border: 0;
  padding: 0;
  -webkit-border-radius: 0;
          border-radius: 0;
  width: 120%;
  position: relative;
  z-index: 10;
  font-size: 1em;
}
