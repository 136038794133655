.question-container{
  padding: 10px 20px 30px;
  width: 100%;
  max-width: 260px;
  display: flex;-js-display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: solid 2px;
  align-items: center;
  text-align: center;
  margin-top: 160px;
  margin-bottom: 100px;
  font-size: 23px;
  font-weight: 200;
  position: absolute;
  top: 0
}
.question-container-inner{
  display: flex;-js-display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center
}
.question-container.one .question-container-inner{
  flex-direction: column;
}
.question-container.three .question-container-inner,
.question-container.four .question-container-inner,
.question-container.seven .question-container-inner,
.question-container.five .question-container-inner{
  flex-direction: column;
}
.question-container.three, .question-container.four, .question-container.five,  .question-container.seven{
  flex-direction: column;
  padding-bottom: 60px;
}
.question-container p{
  margin-bottom: 9px;
}
.question-container hr{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #61cbd6;
  margin: 1em 0;
  padding: 0; 
  width: 300px;
}
.quiz-slide-enter {
  opacity: 0.01;
  transform: scale(0.9) translateX(100px);
  transition: all 1000ms ease-out;
}
.quiz-slide-enter-active {
  opacity: 1;
  transform: scale(1) translateX(0%);
  transition: all 1000ms ease-out;
}
.quiz-slide-exit {
  opacity: 1;
  transform: scale(1) translateX(0%);
}
.quiz-slide-exit-active {
  opacity: 0.01;
  transform: scale(0.9) translateX(-100px);
  transition: all 1000ms ease-out;
}
.question-footer-text{
  font-size: 12px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0)
}
.how-many-cats{
    margin: 30px 0;
}
.how-many-cats button:hover{
    background: #61cbd6;
    color: #fff !important;
}
.breed-selector{
  display: flex;-js-display: flex;
  flex-direction: column;
  min-width: 125px;  
}

.breed-selector button:first-child{
  margin-top: 20px;
}

.weight-selector{
  flex-direction: column;
  position: relative;
  left: -12px;
  min-width: 200px;  
}
.bday-selector{
  display: flex;-js-display: flex;
  flex-direction: column;
  position: relative;
  left: -12px;
  min-width: 200px;  
}

.breed-selector button{
  margin-bottom: 20px;
}

.env-selector{
  display: flex;-js-display: flex;
  flex-direction: column;
  align-items: center
}

.env-selector button{
  margin: 20px 10px 10px 10px
}

.next-six{
  margin-bottom: 30px
}
.eight .question-container-inner{
  padding: 20px 0;
}
.completed-wrap{
  display: flex;-js-display: flex;
  flex-direction: column;
}
.completed-wrap .bag,
.completed-wrap .bags{
  display: flex;-js-display: flex;
  justify-content: center;
  flex: 1;
}
.completed-wrap .bag img,
.completed-wrap .bags img{
  max-width: 100px;
  margin-top: 30px;
  height: auto;
}
.completed-wrap .copy .title{
  font-size: 24px;
  border-bottom: solid 1px;
  display: inline;
}
.completed-wrap .copy .desc{
  font-size: 18px;
}
.question-container.two .ani-error.total3{
  margin-top: 290px;
}
.question-container.two .ani-error.total1{
  margin-top: 112px;
}
.question-container.two .ani-error.total1{
  top: 140px;
}
.question-container.two .ani-error.total2{
  top: 228px;
}
.question-container.three .ani-error.total2{
  margin-top: 185px;
  top: 218px;
}
.question-container.three .ani-error.total3{
  margin-top: 265px;
}
.question-container.four .ani-error.total2{
  margin-top: 285px;
  top: 318px;
}
.question-container.four .ani-error.total3{
  margin-top: 425px;
}
.question-container.five .ani-error.total2{
  margin-top: 270px;
  top: 285px;
}
.question-container.five .ani-error.total3{
  margin-top: 390px;
}
.question-container.six .ani-error{
  margin-left: 18px;
}
.question-container.seven .ani-error.total2{
  margin-top: 220px;
  top: 235px;
}
.question-container.seven .ani-error.total3{
  margin-top: 315px;
}
#GoBack{
  position: absolute;
  z-index: 9999;
  left: 0px;
  bottom: -50px;
  display: flex;-js-display: flex;
  cursor: pointer;
}
#GoBack img{
  max-width: 25px;
}
@media screen and (max-width: 767px){  
  .two .bottom-text{
    margin-top: 0
  }
  .question-container.two .ani-error{
    margin-top: 203px;
  }
  .env-selector button.last{
    margin-right: 0px !important;
  }
  .question-container input,
  .question-container button
  {
    max-width: 210px !important;
  }
  .question-container.six{
    padding-top: 20px;
  }
  .breed-selector.bottom button:first-child{
    margin-top: 0
  }
  .env-selector button.last{
    margin: 20px 10px 20px 10px
  }
}
@media screen and (min-width: 768px){  
  .question-container{
    padding-bottom: 60px;
  }
  .question-container.two .ani-error.total1{
    margin-top: 68px;
  }
  .question-container.two .ani-error.total2{
    margin-top: 113px;
  }
  .question-container.two .ani-error.total3{
    margin-top: 155px;
  }
  .question-container.two{
    padding-top: 20px;
  }
  .question-container.three,
  .question-container.four,
  .question-container.seven,
  .question-container.five{
    padding-bottom: 80px;
  }
  .question-container.six{
    padding-bottom: 20px;
  }
  .question-container.eight{
    padding-bottom: 60px;
  }
  .eight .question-container-inner{
    flex-direction: column;
  }
  .question-footer-text{
    margin-top: 80px;
    font-size: 16px;
    bottom: 20px;
    text-align: unset;
  }
  .question-container-inner{
    flex-direction: row;
    max-width: 700px;
  }
  .question-container{
    padding: 60px 20px 40px;
    max-width: 700px;
    margin-top: 210px;
  }
  .question-container.one .main-btn{
    width: 220px;
    margin-right: 10px;
  }
  .weight-selector{
    flex-direction: row;
    position: relative;
    left: -12px;
    min-width: 270px;  
  }
  .bday-selector{
    flex-direction: row;
    position: relative;
    left: -12px;
    min-width: 125px;  
  }
  .bday-selector .quiz_dropdown:first-child{
    margin-right: 5px;
    margin-left: 20px;
  }
  .breed-selector{
    flex-direction: row;
  }
  .breed-selector button{
    margin: 20px;
  }
  .env-selector{
    margin: 20px 0;
    flex-direction: row;
  }
  .next-six{
    margin-bottom: 60px
  }
  .completed-wrap{
    flex-direction: row;
  }
  .completed-wrap .copy{
    display: flex;-js-display: flex;
    flex-direction: column;
    width: 60%;
    text-align: left;
  }
  .completed-wrap .bag img,
  .completed-wrap .bags img{
    max-width: 200px;
    position: relative;
    top: 10px;
    margin-bottom: 45px
  }
  .completed-wrap .copy .title{
    font-size: 32px;
  }
  .completed-wrap .copy .desc{
    font-size: 24px;
  }
  .completed-wrap .copy button{
    margin-top: 25px;
  }
  .question-container .ani-error.total2{
    top: 148px;
  }
  .question-container.two .ani-error.total2{
    top: 148px;
  }
  .question-container.three .ani-error{
    top: 148px;
  }
  .question-container.three .ani-error.total3{
    margin-top: 180px;
    top: 268px;
  }
  .question-container.four .ani-error{
    margin-top: 110px;
  }
  .question-container .ani-error.total1{
    top: 105px;
  }
  .question-container.four .ani-error.total2{
    margin-top: 225px;
  }
  .question-container.four .ani-error.total3{
    margin-top: 335px;
  }
  .question-container.three .ani-error.total2{
    margin-top: 125px;
  }  
  .question-container.five .ani-error.total2{
    margin-top: 165px;
    top: 238px;
  }
  .question-container.five .ani-error.total3{
    margin-top: 235px;
  }
  .question-container.six .ani-error{
    margin-left: 120px !important;
  }
  .question-container.seven .ani-error.total2{
    margin-top: 165px;
  }
  .question-container.seven .ani-error.total3{
    margin-top: 235px;
  }
}