a{
  border:none;
  outline:none;
}
#page{
  -js-display: flex;
  display: flex;
}

#homePage .main-content{
  padding: 10px;
}

#homePage .header {
  background: #000;
  -js-display: flex;
  display: flex;
  padding: 30px 10px 20px;
  justify-content: center;
}

#homePage .header img{
  max-width: 300px;
}
#homePage .sub-header{
  -js-display: flex;
  display: flex;
  justify-content: center;
}

#homePage .sub-header p{
  text-align: center;
  font-weight: 600;
}

#homePage .main-content{
  -js-display: flex;
  display: flex;
  max-width: 900px;
  margin: 40px auto;
  flex-direction: column;
  align-items: center;
}

#homePage .content-left{
  max-width: 350px;
}

#homePage .content-left img{
  border: solid 3px #eee;
  max-width: 350px;
  width: 100%;
}

@media screen and (max-width: 767px){
  #homePage .content-left img{
    border: solid 2px #eee;
    max-width: 290px;
    width: 100%;
  }
}

#homePage .content-right{
  -js-display: flex;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  font-weight: 300;
  text-align: center;
}
#homePage .content-right h1{
  font-weight: 200;
}
#homePage .content-right li{
  list-style: none;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 0;
  text-align: left;
}
#homePage .content-right li img{
  max-width: 20px;
  margin-right: 10px;
}
button{
  padding: 10px;
  font-size: 22px;
  border: none;
  border-radius: 3px;
  max-width: 375px;
  width: 100%;
  transition: opacity .2s ease-in-out
}
#homePage .content-right button:hover{
  opacity: .7;
}
#homePage .how-it-works{
  -js-display: flex;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
#homePage .how-it-works h2{
  font-weight: 300;
}
#homePage .steps-wrap{
  -js-display: flex;
  display: flex;
  flex-direction: column;
}
#homePage .steps-wrap h3{
  font-weight: 500;
}
#homePage .steps-wrap .step{
  max-width: 270px;
}
#homePage .steps-wrap .step p{
  font-size: 12px;
  font-weight: 200;
}

#homePage .steps-wrap .step img{
  max-width: 100%;
}

#homePage .steps-wrap .step.one img{
  max-width: 80%;
  position: relative;
  top: 1px;
}

#homePage .steps-wrap .step.three img{
  max-width: 75%;
  position: relative;
  top: -3px;
}

#footer-links{
  left: 0;
  width: 100%;
  -js-display: flex;
  display: flex;
  background: #222;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  color: #fff;
  margin-top: 40px;
}

#footer-links.light{
  background: transparent;
  color: #222;
}

#footer-links.light a{
  color: #222;
}

#footer-links a{
  color: #fff;
  text-decoration: none;
}

@media screen and (min-width: 768px){
  #homePage .steps-wrap{
    flex-direction: row;
  }

  #homePage .main-content{
    flex-direction: row;
  }

  #homePage .content-right{
    flex-direction: column;
    padding: 0 0 0 20px;
    font-weight: 300;
    text-align: left;
  }
}


@media screen and (min-width: 800px){
  #homePage .content-right{
    padding: 0 0 0 60px;
  }
}

@media screen and (min-width: 880px){
  #homePage .content-right{
    padding: 0 0 0 80px;
  }
}

.clearfix::after {
    display: block;
    content: "";
    clear: both;
}

